<template>
    <div>
        <page-header :title="title" :items="items" />

        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12 col-md-7 d-flex p-0">
                                <div class="col-10 col-sm-9 form-group">
                                    <input
                                        v-model="newDepartment"
                                        class="form-control"
                                        :class="{
                                            'is-invalid':
                                                $v.newDepartment.$error
                                        }"
                                        placeholder="Department name"
                                    />
                                </div>
                                <div
                                    class="col-3 col-sm-4 form-group d-flex align-items-end"
                                >
                                    <button
                                        class="btn btn-danger waves-effect waves-light"
                                        data-animation="fadein"
                                        data-plugin="custommodal"
                                        data-overlaycolor="#38414a"
                                        @click="createDepartmentHandler"
                                    >
                                        <i class="mdi mdi-plus-circle mr-1" />
                                        Add Department
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div class="table-responsive mb-5">
                            <h5 class="mb-3 text-uppercase">
                                Departments:
                            </h5>
                            <table
                                id="departments-datatable"
                                class="table table-centered table-striped text-center"
                            >
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="department in departments"
                                        :key="department.id"
                                    >
                                        <td>{{ department.name }}</td>
                                        <td @click.stop>
                                            <a
                                                href="#"
                                                class="action-icon"
                                                @click.prevent="
                                                    showEditModal(department)
                                                "
                                            >
                                                <i class="fas fa-pen mr-1" />
                                            </a>
                                            <a
                                                href="#"
                                                class="action-icon"
                                                @click.prevent="
                                                    showDeleteModal(department)
                                                "
                                            >
                                                <i class="far fa-trash-alt" />
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="col-12 mb-5 p-0 form-group">
                            <h5 class="mb-2 text-uppercase">
                                Contact Form
                            </h5>
                            <b-form-select
                                v-model="selectedProduct"
                                :options="productsToDisplay"
                                text-field="name"
                                value-field="id"
                                class="mb-2 form-control"
                            />
                            <b-form-select
                                :key="departmentsKey"
                                v-model="selectedDepartment"
                                :options="departments"
                                text-field="name"
                                value-field="id"
                                class="mb-2 form-control"
                            />
                            <label for="contactEmails">
                                Emails:
                            </label>
                            <textarea
                                id="contactEmails"
                                v-model="selectedEmails"
                                :class="{
                                    'is-invalid': $v.selectedEmails.$error
                                }"
                                type="text"
                                class="mb-2 form-control"
                            />
                            <div class="d-flex flex-wrap">
                                <div
                                    class="form-group col-12 p-0 d-flex justify-content-end"
                                >
                                    <button
                                        type="button"
                                        class="btn btn-success waves-effect waves-light"
                                        @click="addGroup"
                                    >
                                        Add Group
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mb-5 p-0 form-group">
                            <div v-if="emails.length">
                                <div
                                    v-for="(item, index) in emails"
                                    :key="item.id"
                                >
                                    <div class="form-group mb-5">
                                        <h5>
                                            {{ item.product.name }} -
                                            {{ item.department.name }}
                                        </h5>
                                        <label for="contactEmails">
                                            Emails:
                                        </label>
                                        <textarea
                                            id="contactEmails"
                                            v-model="item.emails"
                                            type="text"
                                            class="form-control"
                                            :class="{
                                                'is-invalid':
                                                    $v.emails.$each.$iter[index]
                                                        .emails.$error
                                            }"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex flex-wrap">
                                <div
                                    class="form-group p-0 col-12 d-flex justify-content-end"
                                >
                                    <button
                                        type="button"
                                        class="btn btn-success waves-effect waves-light"
                                        @click="save"
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- end card-body-->
                </div>
                <!-- end card-->
            </div>
            <!-- end col -->
        </div>
        <!-- end row -->

        <modal-contact-edit
            :modal-title="'Edit Department'"
            :button-text="'Update'"
            :department="departmentToUpdate"
            @hidden="hiddenModalContactEditHandler"
            @updateDepartment="updateDepartmentHandler"
        />

        <confirm-modal
            title="Do you want to delete this department?"
            @agree="deleteDepartmentHandler"
            @disagree="departmentToUpdate = null"
        />
    </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { mapActions } from 'vuex';
import config from '@config';
import ModalContactEdit from '@components/contact/ModalContactEdit';
import ConfirmModal from '@components/elements/ConfirmModal';

export default {
    page: {
        title: 'Contact',
        meta: [{ name: 'description', content: config.description }]
    },

    components: {
        ModalContactEdit,
        ConfirmModal
    },

    computed: {
        productsToDisplay() {
            return this.products.filter(
                product =>
                    product.eng_name !== 'Saxenda®' &&
                    product.eng_name !== 'Wegovy®'
            );
        }
    },

    validations: {
        selectedEmails: {
            required,
            containEmails: value => {
                return /^[\W]*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4}[\W]*,{1}[\W]*)*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4})[\W]*$/.test(
                    value
                );
            }
        },
        emails: {
            $each: {
                emails: {
                    containEmails: value => {
                        return /^[\W]*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4}[\W]*,{1}[\W]*)*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4})[\W]*$/.test(
                            value
                        );
                    }
                }
            }
        },
        newDepartment: {
            required
        }
    },

    data() {
        return {
            title: 'Contact',
            items: [
                {
                    text: 'NovoCare',
                    to: '/'
                },
                {
                    text: 'Users',
                    active: true
                }
            ],
            departmentsKey: 0,
            departments: [],
            products: [],
            emails: [],
            newDepartment: null,
            departmentToUpdate: null,
            selectedProduct: null,
            selectedDepartment: null,
            selectedEmails: ''
        };
    },

    async mounted() {
        await this.fetchDepartments();
        await this.fetchProducts();
        await this.fetchEmails();
    },

    methods: {
        ...mapActions({
            getProducts: 'products/index',
            getEmailGroups: 'contact/getEmailGroups',
            addEmailGroup: 'contact/addEmailGroup',
            updateEmailGroups: 'contact/updateEmailGroups',
            getDepartments: 'contact/index',
            createDepartment: 'contact/store',
            updateDepartment: 'contact/update',
            deleteDepartment: 'contact/destroy'
        }),

        async fetchDepartments() {
            const response = await this.getDepartments();

            this.departments = [...response];
            this.selectedDepartment = this.departments[0].id;
        },

        async fetchProducts() {
            this.products = await this.getProducts();

            this.selectedProduct = this.products.length
                ? this.products[0].id
                : null;
        },

        async fetchEmails() {
            const response = await this.getEmailGroups();

            this.emails = [...response];
        },

        hiddenModalContactEditHandler() {
            this.departmentToUpdate = null;
        },

        showDeleteModal(department) {
            this.departmentToUpdate = department;

            this.$bvModal.show('confirmModal');
        },

        showEditModal(department) {
            this.departmentToUpdate = department;
            this.$bvModal.show('modalContactEdit');
        },

        async createDepartmentHandler() {
            this.$v.newDepartment.$touch();

            if (this.$v.newDepartment.$invalid) {
                return;
            }

            try {
                const department = await this.createDepartment({
                    name: this.newDepartment
                });

                this.departments = [...this.departments, department];
                this.newDepartment = '';
                this.$v.newDepartment.$reset();
            } catch (err) {
                console.error(err);

                this.$bvToast.toast('Something went wrong!', {
                    title: 'Error!',
                    variant: 'danger',
                    solid: true
                });
            }
        },

        async updateDepartmentHandler(data) {
            try {
                const response = await this.updateDepartment(data);

                this.departments.forEach((element, index) => {
                    if (element.id === response.id) {
                        this.departments[index] = response;
                    }
                });

                this.emails.forEach((element, index) => {
                    if (element.department_id === response.id) {
                        this.emails[index].department.name = response.name;
                    }
                });

                this.departmentsKey += 1;
            } catch (err) {
                console.error(err);

                this.$bvToast.toast('Something went wrong!', {
                    title: 'Error!',
                    variant: 'danger',
                    solid: true
                });
            }
        },

        async deleteDepartmentHandler() {
            try {
                await this.deleteDepartment(this.departmentToUpdate);

                this.departments = this.departments.filter(
                    item => item.id !== this.departmentToUpdate.id
                );

                this.emails = this.emails.filter(
                    item => item.department_id !== this.departmentToUpdate.id
                );

                if (this.selectedDepartment === this.departmentToUpdate.id) {
                    this.selectedDepartment = this.departments.length
                        ? this.departments[0].id
                        : null;
                }

                this.departmentToUpdate = null;
            } catch (err) {
                console.error(err);

                this.$bvToast.toast('Something went wrong!', {
                    title: 'Error!',
                    variant: 'danger',
                    solid: true
                });
            }
        },

        async addGroup() {
            this.$v.selectedEmails.$touch();
            if (this.$v.selectedEmails.$invalid) {
                return;
            }

            try {
                const response = await this.addEmailGroup({
                    departmentId: this.selectedDepartment,
                    productId: this.selectedProduct,
                    emails: this.selectedEmails
                });

                this.emails = [response, ...this.emails];
                this.$v.selectedEmails.$reset();
                this.selectedEmails = '';
            } catch (err) {
                console.error(err);

                if (err.response.data.errors.exists) {
                    const message = err.response.data.errors.exists;

                    this.$bvToast.toast(message, {
                        title: 'Error!',
                        variant: 'danger',
                        solid: true
                    });
                }
            }
        },

        async save() {
            this.$v.emails.$touch();
            if (this.$v.emails.$invalid) {
                return;
            }

            const values = [];
            this.emails.forEach(item => {
                values.push({
                    id: item.id,
                    emails: item.emails
                });
            });

            try {
                await this.updateEmailGroups({ data: values });

                this.$bvToast.toast('Emails have been updated', {
                    title: 'Success!',
                    variant: 'success',
                    solid: true
                });

                this.$v.emails.$reset();
            } catch (err) {
                console.error(err);

                this.$bvToast.toast('Something went wrong!', {
                    title: 'Error!',
                    variant: 'danger',
                    solid: true
                });
            }
        }
    }
};
</script>
